import { Component, ElementRef, HostListener } from '@angular/core';

@Component({
  selector: 'app-three-jsscene',
  standalone: true,
  imports: [],
  templateUrl: './three-jsscene.component.html',
  styleUrls: ['./three-jsscene.component.scss']
})
export class ThreeJSSceneComponent {
  private canvas: HTMLCanvasElement | null = null;
  private ctx: CanvasRenderingContext2D | null = null;
  private width: number = window.innerWidth;
  private height: number = window.innerHeight;
  private mouse = { x: null as number | null, y: null as number | null };
  private lastMouse = { x: 0, y: 0 };
  private tent: any[] = [];
  private target = { x: 0, y: 0, errx: 0, erry: 0 };
  private lastTarget = { x: 0, y: 0 };
  private t: number = 0;
  private maxl = 300;
  private minl = 50;
  private n = 30;
  private numt = 500;
  private clicked = false;
  private idleTime = 0;
  private idleInterval: any;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.canvas = this.el.nativeElement.querySelector('#canvas');
    this.ctx = this.canvas!.getContext('2d')!;
    this.initCanvas();
    this.setupTentacles();
    this.loop();
    this.startIdleTimer();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.updateCanvasSize();
    this.initCanvas();
    this.setupTentacles();
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event: Event): void {
    this.updateCanvasSize();
    this.initCanvas();
    this.setupTentacles();
  }

  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event: MouseEvent): void {
    this.lastMouse.x = this.mouse.x ?? this.lastMouse.x;
    this.lastMouse.y = this.mouse.y ?? this.lastMouse.y;

    this.mouse.x = event.pageX - this.canvas!.offsetLeft;
    this.mouse.y = event.pageY - this.canvas!.offsetTop;
    this.idleTime = 0;
  }

  @HostListener('window:mouseleave', ['$event'])
  onMouseLeave(event: MouseEvent): void {
    this.mouse.x = null;
    this.mouse.y = null;
  }

  @HostListener('window:mousedown', ['$event'])
  onMouseDown(event: MouseEvent): void {
    this.clicked = true;
  }

  @HostListener('window:mouseup', ['$event'])
  onMouseUp(event: MouseEvent): void {
    this.clicked = false;
  }

  private updateCanvasSize(): void {
    this.width = this.canvas!.width = window.innerWidth;
    this.height = this.canvas!.height = window.innerHeight;
  }

  private initCanvas(): void {
    this.updateCanvasSize();
    this.ctx!.fillStyle = 'rgba(30,30,30,1)';
    this.ctx!.fillRect(0, 0, this.width, this.height);
  }

  private setupTentacles(): void {
    this.tent = []; // Clear existing tentacles
    for (let i = 0; i < this.numt; i++) {
      this.tent.push(
        new Tentacle(
          Math.random() * this.width,
          Math.random() * this.height,
          Math.random() * (this.maxl - this.minl) + this.minl,
          this.n,
          Math.random() * 2 * Math.PI,
          this.ctx!
        )
      );
    }
  }

  private loop(): void {
    window.requestAnimationFrame(() => this.loop());
    this.ctx!.clearRect(0, 0, this.width, this.height);
    this.draw();
  }

  private draw(): void {
    if (this.mouse.x !== null && this.mouse.y !== null) {
      this.target.errx = this.mouse.x - this.target.x;
      this.target.erry = this.mouse.y - this.target.y;
    } else {
      this.target.errx =
        this.width / 2 +
        ((this.height / 2 - 10) * Math.sqrt(2) * Math.cos(this.t)) /
        (Math.pow(Math.sin(this.t), 2) + 1) -
        this.target.x;
      this.target.erry =
        this.height / 2 +
        ((this.height / 2 - 10) * Math.sqrt(2) * Math.cos(this.t) * Math.sin(this.t)) /
        (Math.pow(Math.sin(this.t), 2) + 1) -
        this.target.y;
    }

    this.target.x += this.target.errx / 10;
    this.target.y += this.target.erry / 10;

    this.t += 0.01;

    this.ctx!.beginPath();
    this.ctx!.arc(
      this.target.x,
      this.target.y,
      this.dist(this.lastTarget.x, this.lastTarget.y, this.target.x, this.target.y) + 5,
      0,
      2 * Math.PI
    );
    this.ctx!.fillStyle = 'hsl(210,100%,80%)';
    this.ctx!.fill();

    for (let i = 0; i < this.numt; i++) {
      this.tent[i].move(this.lastTarget, this.target);
      this.tent[i].show2(this.target);
    }
    for (let i = 0; i < this.numt; i++) {
      this.tent[i].show(this.target);
    }
    this.lastTarget.x = this.target.x;
    this.lastTarget.y = this.target.y;
  }

  private dist(p1x: number, p1y: number, p2x: number, p2y: number): number {
    return Math.sqrt(Math.pow(p2x - p1x, 2) + Math.pow(p2y - p1y, 2));
  }

  private startIdleTimer(): void {
    this.idleInterval = setInterval(() => {
      this.idleTime++;
      if (this.idleTime >= 5) { // 5 seconds of inactivity
        this.mouse.x = null;
        this.mouse.y = null;
      }
    }, 1500); // Check every 1.5 seconds
  }
}

class Segment {
  public pos: { x: number; y: number };
  public nextPos: { x: number; y: number };
  constructor(
    private parent: any,
    public l: number,
    public ang: number,
    public first: boolean,
    private ctx: CanvasRenderingContext2D
  ) {
    if (first) {
      this.pos = { x: parent.x, y: parent.y };
    } else {
      this.pos = { x: parent.nextPos.x, y: parent.nextPos.y };
    }
    this.nextPos = {
      x: this.pos.x + this.l * Math.cos(this.ang),
      y: this.pos.y + this.l * Math.sin(this.ang)
    };
  }
  update(t: { x: number; y: number }) {
    this.ang = Math.atan2(t.y - this.pos.y, t.x - this.pos.x);
    this.pos.x = t.x + this.l * Math.cos(this.ang - Math.PI);
    this.pos.y = t.y + this.l * Math.sin(this.ang - Math.PI);
    this.nextPos.x = this.pos.x + this.l * Math.cos(this.ang);
    this.nextPos.y = this.pos.y + this.l * Math.sin(this.ang);
  }
  fallback(t: { x: number; y: number }) {
    this.pos.x = t.x;
    this.pos.y = t.y;
    this.nextPos.x = this.pos.x + this.l * Math.cos(this.ang);
    this.nextPos.y = this.pos.y + this.l * Math.sin(this.ang);
  }
  show() {
    this.ctx.lineTo(this.nextPos.x, this.nextPos.y);
  }
}

class Tentacle {
  public segments: Segment[] = [];
  public t: any;
  public rand: number;
  public angle: number = 0;
  public dt: number = 0;

  constructor(
    public x: number,
    public y: number,
    public l: number,
    public n: number,
    public a: number,
    private ctx: CanvasRenderingContext2D
  ) {
    this.t = {};
    this.rand = Math.random();
    this.segments = [new Segment(this, this.l / this.n, 0, true, this.ctx)];
    for (let i = 1; i < this.n; i++) {
      this.segments.push(
        new Segment(this.segments[i - 1], this.l / this.n, 0, false, this.ctx)
      );
    }
  }

  move(lastTarget: { x: number; y: number }, target: { x: number; y: number }) {
    this.angle = Math.atan2(target.y - this.y, target.x - this.x);
    this.dt = this.dist(lastTarget.x, lastTarget.y, target.x, target.y) + 5;
    this.t = {
      x: target.x - 0.8 * this.dt * Math.cos(this.angle),
      y: target.y - 0.8 * this.dt * Math.sin(this.angle)
    };
    if (this.t.x) {
      this.segments[this.n - 1].update(this.t);
    } else {
      this.segments[this.n - 1].update(target);
    }
    for (let i = this.n - 2; i >= 0; i--) {
      this.segments[i].update(this.segments[i + 1].pos);
    }
    if (
      this.dist(this.x, this.y, target.x, target.y) <=
      this.l + this.dist(lastTarget.x, lastTarget.y, target.x, target.y)
    ) {
      this.segments[0].fallback({ x: this.x, y: this.y });
      for (let i = 1; i < this.n; i++) {
        this.segments[i].fallback(this.segments[i - 1].nextPos);
      }
    }
  }

  show(target: { x: number; y: number }) {
    if (this.dist(this.x, this.y, target.x, target.y) <= this.l) {
      this.ctx.globalCompositeOperation = 'lighter';
      this.ctx.beginPath();
      this.ctx.lineTo(this.x, this.y);
      for (let i = 0; i < this.n; i++) {
        this.segments[i].show();
      }
      this.ctx.strokeStyle =
        'hsl(' +
        (this.rand * 60 + 180) +
        ',100%,' +
        (this.rand * 60 + 25) +
        '%)';
      this.ctx.lineWidth = this.rand * 2;
      this.ctx.lineCap = 'round';
      this.ctx.lineJoin = 'round';
      this.ctx.stroke();
      this.ctx.globalCompositeOperation = 'source-over';
    }
  }

  show2(target: { x: number; y: number }) {
    this.ctx.beginPath();
    if (this.dist(this.x, this.y, target.x, target.y) <= this.l) {
      this.ctx.arc(this.x, this.y, 2 * this.rand + 1, 0, 2 * Math.PI);
      this.ctx.fillStyle = 'white';
    } else {
      this.ctx.arc(this.x, this.y, this.rand * 2, 0, 2 * Math.PI);
      this.ctx.fillStyle = 'darkcyan';
    }
    this.ctx.fill();
  }

  private dist(p1x: number, p1y: number, p2x: number, p2y: number): number {
    return Math.sqrt(Math.pow(p2x - p1x, 2) + Math.pow(p2y - p1y, 2));
  }
}
