<div class="container">

  <div  class="service-section">
    <div class="section-gap-bottom-130">
      <div class="container w-container">
        <div class="section-justify-center">
          <div class="section-block max-width-475 text-center">
            <h2 class="section-title">Services <span class="section-title-inner-style">that we </span>Provide</h2>
            <div class="section-text">
              <p>Empower your digital presence with our cutting-edge IT solutions.
              We are a startup IT company dedicated to crafting exceptional web experiences for
              businesses of all sizes. Our services span a wide range of expertise, ensuring your online success and
                growth. </p>
            </div>
          </div>
        </div>

        <div class="service-collection-list-wrapper w-dyn-list">
          <div role="list" class="service-collection-list w-dyn-items">
            <div role="listitem" class="service-collection-item w-dyn-item vanillaTitle">
              <figure class="service-single-item-box">
                <div class="service-thumbnail-image-block w-inline-block">
                  <img alt="IT Management"
                       src="https://assets.website-files.com/633413e96a4b660aa7000422/6350e2378bd992a643b58fe4_service-main-image.jpg"
                       sizes="(max-width: 479px) 100vw, (max-width: 767px) 40vw, (max-width: 991px) 295px, (max-width: 1279px) 250px, 320px"
                       srcset="https://assets.website-files.com/633413e96a4b660aa7000422/6350e2378bd992a643b58fe4_service-main-image-p-500.jpg 500w,
                       https://assets.website-files.com/633413e96a4b660aa7000422/6350e2378bd992a643b58fe4_service-main-image.jpg 545w"
                       class="image-responsive">
                </div>
                <figcaption class="service-content">
                  <div class="service-title-link">IT Management</div>
                  <div class="service-text">
                    <p>Optimize your IT infrastructure with our expert management solutions,
                      ensuring seamless operations and enhanced efficiency.</p>
                  </div>
                </figcaption>
              </figure>
            </div>


            <div role="listitem" class="service-collection-item w-dyn-item vanillaTitle">
              <figure class="service-single-item-box">
                <div class="service-thumbnail-image-block w-inline-block">

                  <img
                    style="max-width: 100%; height: auto;"
                    alt="Analytic Solutions"
                    loading="lazy"
                    src="src/assets/images/game-art.jpg"
                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 40vw, (max-width: 991px) 295px, (max-width: 1279px) 250px, 320px"
                    srcset="/assets/images/game-art.jpg 1500w"
                    class="image-responsive">
                </div>
                <figcaption class="service-content">
                  <div class="service-title-link">Game art</div>
                  <div class="service-text">
                    <p>Our artists endowed with rich imagination, boundless fantasy and a love to create creative games.
                      They are always focused on the success and the quality of their works.</p>

                  </div>
                </figcaption>
              </figure>
            </div>

            <div role="listitem" class="service-collection-item w-dyn-item vanillaTitle">
              <figure class="service-single-item-box">
                <div class="service-thumbnail-image-block w-inline-block">
                  <img alt="Web Development" loading="lazy"
                       src="https://assets.website-files.com/633413e96a4b660aa7000422/6350e1930d6febaefead6168_service-main-image-4.jpg"
                       sizes="(max-width: 479px) 100vw, (max-width: 767px) 40vw, (max-width: 991px) 295px, (max-width: 1279px) 250px, 320px"
                       srcset="https://assets.website-files.com/633413e96a4b660aa7000422/6350e1930d6febaefead6168_service-main-image-4-p-500.jpg 500w, https://assets.website-files.com/633413e96a4b660aa7000422/6350e1930d6febaefead6168_service-main-image-4.jpg 545w"
                       class="image-responsive">
                </div>
                <figcaption class="service-content">
                  <div class="service-title-link">Web Development</div>
                  <div class="service-text">
                    <p>Bring your ideas to life with our innovative web development services,
                      creating visually stunning and user-friendly websites.</p>
                  </div>
                </figcaption>
              </figure>
            </div>

            <div role="listitem" class="service-collection-item w-dyn-item vanillaTitle">
              <figure class="service-single-item-box">
                <div class="service-thumbnail-image-block w-inline-block">
                  <img alt="Software Development" loading="lazy"
                       src="https://assets.website-files.com/633413e96a4b660aa7000422/6350e176b6de2e0c77c3d26d_service-main-image-5.jpg"
                       sizes="(max-width: 479px) 100vw, (max-width: 767px) 40vw, (max-width: 991px) 295px, (max-width: 1279px) 250px, 320px"
                       srcset="https://assets.website-files.com/633413e96a4b660aa7000422/6350e176b6de2e0c77c3d26d_service-main-image-5-p-500.jpg 500w, https://assets.website-files.com/633413e96a4b660aa7000422/6350e176b6de2e0c77c3d26d_service-main-image-5.jpg 545w"
                       class="image-responsive">
                </div>
                <figcaption class="service-content">
                  <div class="service-title-link">Software Development</div>
                  <div class="service-text">
                    <p>We have a great experience in the project management and software development,
                      capable of handling projects of any size and complexity.</p>
                  </div>
                </figcaption>
              </figure>
            </div>


            <div role="listitem" class="service-collection-item w-dyn-item vanillaTitle">
              <figure class="service-single-item-box">
                <div class="service-thumbnail-image-block w-inline-block">
                  <img alt="IT Consultancy" loading="lazy"
                       src="https://assets.website-files.com/633413e96a4b660aa7000422/6350e139f2896c5d1aaa3616_service-main-image-6.jpg"
                       sizes="(max-width: 479px) 100vw, (max-width: 767px) 40vw, (max-width: 991px) 295px, (max-width: 1279px) 250px, 320px"
                       srcset="https://assets.website-files.com/633413e96a4b660aa7000422/6350e139f2896c5d1aaa3616_service-main-image-6-p-500.jpg 500w, https://assets.website-files.com/633413e96a4b660aa7000422/6350e139f2896c5d1aaa3616_service-main-image-6.jpg 545w"
                       class="image-responsive">
                </div>
                <figcaption class="service-content">
                  <div class="service-title-link">IT Consultancy</div>
                  <div class="service-text">
                    <p>Receive expert guidance and strategic IT consultancy to navigate the digital
                      landscape and achieve your business objectives.</p>
                  </div>
                </figcaption>
              </figure>
            </div>

            <div role="listitem" class="service-collection-item w-dyn-item vanillaTitle">
              <figure class="service-single-item-box">
                <div class="service-thumbnail-image-block w-inline-block">
                  <img
                    alt="Crypto Billing Service"
                    src="/assets/images/crypto.jpg"
                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 40vw, (max-width: 991px) 295px, (max-width: 1279px) 250px, 320px"
                    srcset="/assets/images/crypto.jpg 880w"
                    class="image-responsive">
                </div>
                <figcaption class="service-content">
                  <div class="service-title-link">Crypto Billing Service</div>
                  <div class="service-text">
                    <p>Explore our innovative crypto billing service, providing secure and
                      efficient cryptocurrency payment solutions for your business transactions.</p>
                  </div>
                </figcaption>
              </figure>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="work-content">
    <div class="container w-container">

    <div class="section-justify-center">
      <div class="section-block max-width-475 text-center">
        <h2 class="section-title">How <span class="section-title-inner-style">we</span> work</h2>
        <div class="section-text">
          <p>At <span class="company-name"> Otecsys Plus </span>, our approach to work is guided by
          innovation, collaboration, and a commitment to excellence.
          We follow a systematic process that ensures seamless project execution and successful outcomes.
          Here's a glimpse into how we work:</p>
        </div>
      </div>
    </div>
    </div>


    <div class="work-progress-block">
      <div class="single-work-progress-item ">
        <div class="work-progress-icon-block">
          <img
            src="https://assets.website-files.com/6330105a320e0e0ed5adaf1c/63352caa35c0c771a2145cfd_work-proess-1.png"
            alt="Word Process Icon. Meet the Client" class="work-progress-icon-image">
        </div>
        <div class="work-progress-text">Meet the Client</div>
        <div class="work-precess-number-text">01</div>
        <div class="work-progress-horizontal-line-bottom"></div>
      </div>


      <div class="single-work-progress-item">
        <div class="work-progress-icon-block">
          <img
            src="https://assets.website-files.com/6330105a320e0e0ed5adaf1c/63352caac167287006189515_work-proess-2.png"
            alt="Word Process Icon. Brainstorming" class="work-progress-icon-image">
        </div>

        <div class="work-progress-text">Brainstorming</div>
        <div class="work-precess-number-text">02</div>
        <div class="work-progress-horizontal-line-bottom"></div>
      </div>


      <div class="single-work-progress-item">
        <div class="work-progress-icon-block">
          <img
            src="https://assets.website-files.com/6330105a320e0e0ed5adaf1c/63352caa80b0d800d1f700ef_work-proess-3.png"
            loading="lazy"
            alt="Word Process Icon. Find the Solution"
            class="work-progress-icon-image">
        </div>
        <div class="work-progress-text">Find the Solution</div>
        <div class="work-precess-number-text">03</div>
        <div class="work-progress-horizontal-line-bottom"></div>
      </div>

      <div class="single-work-progress-item">
        <div class="work-progress-icon-block">
          <img
            src="https://assets.website-files.com/6330105a320e0e0ed5adaf1c/63352caa9fda7ae99fe80120_work-proess-4.png"
            alt="Word Process Icon. Project Done" class="work-progress-icon-image">
        </div>
        <div class="work-progress-text">Project Done</div>
        <div class="work-precess-number-text">04</div>
        <div class="work-progress-horizontal-line-bottom"></div>
      </div>
    </div>


  </div>

  <div  class="sent-message-container" leaveClass="fadeInRight" enterClass="fadeInRight"  id="ServiceSection">
    <div class="section-block max-width-475 text-center">
      <h2 class="section-title">Send <span class="section-title-inner-style">us</span> a message</h2>
      <div >Have a question or want to discuss a project?</div>
      <div>Feel free to reach out to us by filling out the form below.</div>
    </div>

    <form [formGroup]="form" (ngSubmit)="sendMessage()">
      <div class="user-info">
        <p-floatLabel class="p-float-label">
          <input pInputText id="email" type="email" formControlName='email' autocomplete="true"/>
          <label for="email">Email</label>
        </p-floatLabel>
        <p-floatLabel class="p-float-label">
          <input pInputText id="category" type="text" formControlName='subject'/>
          <label for="category">Subject</label>
        </p-floatLabel>
      </div>
      <p-floatLabel class="p-float-label message-text">
        <textarea rows="8" cols="30" id="message" formControlName='message' pInputTextarea></textarea>
        <label for="message">Message</label>
      </p-floatLabel>

      <div class="input-box buttons">
        <p-button
          [disabled]="form.invalid || form.disabled"
          type="submit"
          i18n="@@Transfer"
          label="Send">
        </p-button>
      </div>
    </form>
  </div>
</div>
