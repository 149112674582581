<div  class="sent-message-container" leaveClass="fadeInRight" enterClass="fadeInRight"  id="ServiceSection">

  <form [formGroup]="form" (ngSubmit)="sendMessage()">
    <div class="user-info">
      <div class="input-box">
        <p-floatLabel class="p-float-label">
          <input pInputText id="email" type="email" formControlName='email' placeholder="example@domin.com"/>
          <label for="email">Email</label>
        </p-floatLabel>
        <small class="p-error block" *ngIf="form.invalid && form.get('email')?.touched ">
          <ng-container class="p-error block" *ngIf="form.get('email')?.hasError('required')">
            Email  is required
          </ng-container>
          <ng-container *ngIf="form.controls['email'].hasError('email')">
            Please enter a valid email address
          </ng-container>
        </small>
      </div>


      <div class="input-box">
        <p-floatLabel class="p-float-label">
          <input pInputText id="category" type="text" formControlName='subject'/>
          <label for="category">Subject</label>
        </p-floatLabel>

        <small class="p-error block" *ngIf="form.invalid && form.get('subject')?.touched ">
          <ng-container class="p-error block" *ngIf="form.get('subject')?.hasError('required')">
            Subject  is required
          </ng-container>
        </small>
      </div>

    </div>

    <div class="input-box textarea">
      <p-floatLabel class="p-float-label message-text">
        <textarea
          pInputTextarea
          rows="8"
          cols="30"
          id="message"
          formControlName='message'></textarea>
        <label for="message">Message</label>
      </p-floatLabel>

      <small class="p-error block" *ngIf="form.invalid && form.get('message')?.touched ">
        <ng-container class="p-error block" *ngIf="form.get('message')?.hasError('required')">
          Message is required
        </ng-container>
      </small>
    </div>


    <div class="input-box buttons">
      <p-button
        [disabled]="form.invalid || form.disabled"
        type="submit"
        i18n="@@Transfer"
        label="Send">
      </p-button>
    </div>
  </form>
</div>
