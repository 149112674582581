import {Routes} from '@angular/router';
import {HomeComponent} from "./shared/components/home/home.component";
import {AboutUsComponent} from "./shared/components/about-us/about-us.component";
import {ContactsComponent} from "./shared/components/contacts/contacts.component";
import {ServicesComponent} from "./shared/components/services/services.component";
import {ProductsComponent} from "./shared/components/products/products.component";
import {GetpartnersComponent} from "./shared/components/getpartners/getpartners.component";
import {ProductDetailsComponent} from "./shared/components/product-details/product-details.component";

export const routes: Routes = [
  {path: 'home', component: HomeComponent},
  {path: 'about-us', component: AboutUsComponent},
  {path: 'products', component: ProductsComponent},
  {path: 'services', component: ServicesComponent},
  {path: 'partners', component: GetpartnersComponent},
  {path: 'contacts', component: ContactsComponent},
  {path: 'product/:id', component: ProductDetailsComponent},
  {path: '', redirectTo: '/home', pathMatch: 'full'},
];
