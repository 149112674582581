import {Component} from '@angular/core';
import {BallAnimationComponent} from "../ball-animation/ball-animation.component";
import {Platform} from "@angular/cdk/platform";
import {CommonModule} from "@angular/common";

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [BallAnimationComponent, CommonModule],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {

  public isMobileView!: boolean;


  constructor(private platform: Platform) {
  }
  public ngOnInit() {
    this.isMobileView = this.platform.IOS || this.platform.ANDROID;
  }
  // @ViewChild('backgroundVideo', { static: true }) backgroundVideo!: ElementRef;

  // ngAfterViewInit() {
  //
  //   const video = this.backgroundVideo.nativeElement as HTMLVideoElement;
  //
  //
  //   if (video.paused) {
  //     video.play()
  //       .then(() => {
  //         console.log('Video is playing');
  //       })
  //       .catch(error => {
  //         console.error('Error playing video:', error);
  //       });
  //   }
  // }

}
