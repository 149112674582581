<!--<canvas #canvas id="canvas"></canvas>-->

<!--&lt;!&ndash; <button id="fullscr">Go Fullscreen</button> &ndash;&gt;-->


<!--<canvas id="lines"></canvas>-->
<!--<h1>The Fus<span class="last">e</span></h1>-->


<canvas id="canvas" width="800" height="600"></canvas>
