
<div class="home-wrapper">
  <div class="home-content">
<!--    <video id="background-video" playsinline autoplay muted loop>-->
<!--      <source src="assets/video/7.mp4" type="video/mp4">-->
<!--      Your browser does not support the video tag.-->
<!--    </video>-->
  </div>

  <div class="description"  [ngClass]="{'mobile-view': isMobileView}">
    <h1 class="section-title " [ngClass]="{'mobile-view': isMobileView}">
      Otecsys <span class="section-title-inner-style">Plus </span>
    </h1>
    <div>Solutions from peoples - for peoples.</div>
  </div>

</div>



<!--<app-rider>-->

<!--</app-rider>-->


<!--<app-wave></app-wave>-->
