<div class="container">
  <div class="title-content">
    <h2 class="section-title"> <span class="section-company-name">  Let’s talk. </span> We’d love to hear from you.</h2>
  </div>

  <div class="contact-container">
    <div class="map" >
      <p-image src="assets/images/map.png"  alt="map" [preview]="true"></p-image>
    </div>

    <div class="contact-info" >
      <div class="contact-widget">
        <div class="contact-block">
          <div class="contact-item">
            <span aria-label="map marker"><i class="pi pi-map-marker" role="img" aria-hidden="true"></i></span>
            <a href="https://www.google.com/maps?q=40.2217,44.492944" target="_blank">Armenia, Yerevan, Davtashen, Pirumyanneri 45/7</a>
          </div>

          <div class="contact-item">
            <span aria-label="phone"> <i class="pi pi-phone" role="img" aria-hidden="true"></i></span>
            <a href="tel:+37497282200">(+374) 97 28 22 00</a>
          </div>
          <div class="contact-item">
            <span aria-label="e-mail"><i class="pi pi-envelope" role="img" aria-hidden="true"></i></span>
            <a href="mailto:info&#64;otecsys.org" class="footer-contact-link-item">info&#64;otecsys.org</a>
          </div>
        </div>

        <div class="social-media">
          <ul>
            <li>
              <a href="https://www.facebook.com/Otecsys/" aria-label="Share on Facebook">
                <i class="fab fa-facebook-f icon"></i>
              </a>
            </li>

            <li>
              <a href="https://www.behance.net/search/projects/otecsys?tracking_source=typeahead_nav_recent_suggestion"
                 target="_blank" aria-label="Share on Behance">
                <i class="fab fa-behance icon"></i>
              </a>
            </li>

            <li>
              <a href="https://www.linkedin.com/in/otecsysorg/"
                 target="_blank" aria-label="Share on LinkedIn">
                <i class="fab fa-linkedin-in icon"></i>
              </a>
            </li>

          </ul>

        </div>
      </div>
    </div>
  </div>
</div>
