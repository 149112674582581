import {Component, OnInit} from '@angular/core';
import {ThemeService} from "../../services/theme.service";
import {NavigationService} from "../../services/navigation.service";
import {RouterModule} from "@angular/router";
import {SidebarModule} from "primeng/sidebar";
import {ButtonModule} from "primeng/button";
import {ToggleComponent} from "../toggle/toggle.component";

@Component({
  selector: 'app-side-bar',
  standalone: true,
  imports: [RouterModule, SidebarModule, ButtonModule, ToggleComponent],
  templateUrl: './side-bar.component.html',
  styleUrl: './side-bar.component.scss'
})
export class SideBarComponent implements OnInit {

  public currentTheme!: 'light' | 'dark';
  public sidebarVisible: boolean = false;
  public logoUrl!: string;


  constructor(private themeService: ThemeService,
              private navigationService: NavigationService,) {
  }

  public isLightTheme(): boolean {
    return this.themeService.getCurrentTheme() === 'light';
  }

  public ngOnInit() {
    this.navigationService.openLeftBar$.subscribe((res: any) => {
      this.sidebarVisible = !!res.value;
    })
    this.getLogoBtTheme();
    this.themeService.themeChanged.subscribe(theme => {
      this.getLogoBtTheme();
    });
  }

  public getLogoBtTheme() {
    let theme = localStorage.getItem('currentTheme')
    if (theme === 'dark' || !theme) {
      this.logoUrl = 'assets/images/Logo3.png'
    } else {
      this.logoUrl = 'assets/images/Logo1.png'
    }
  }
}
