import {Component} from '@angular/core';
import {CarouselComponent} from "../carousel/carousel.component";
import {RouterModule} from "@angular/router";

@Component({
  selector: 'app-products',
  standalone: true,
  imports: [CarouselComponent, RouterModule],
  templateUrl: './products.component.html',
  styleUrl: './products.component.scss'
})
export class ProductsComponent {

}
