<!-- get-partners.component.html -->

<section class="partners-page">

  <h2 class="section-title">Partner with <span class="section-company-name">Otecsys Plus </span></h2>

  <div class="container">
    <div class="section-1">
      <div class="section-1-info">
        <p>Welcome to the <em class="company-name">Otecsys Plus </em>Partner Program! We value collaboration and believe
          in the power of partnerships to drive innovation and mutual success. Whether you're a technology enthusiast, a
          reseller, or a strategic ally, we invite you to explore the exciting possibilities of joining forces with
          Otecsys Plus.</p>
        <p-toast [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}"
                 [showTransitionOptions]="'1000ms'"
                 [hideTransitionOptions]="'1000ms'"
                 [showTransformOptions]="'translateX(100%)'"></p-toast>
        <div class="join-btn">
          <button type="button" (click)="showPopUp()">Become a partner</button>
        </div>
      </div>
      <div class="section-1-img">
        <img [src]='"/assets/images/business%20partnership%20illustration.png"' alt="Partners">
      </div>

    </div>



    <div class="section-3">
      <div class="join-section">
        <div class="caption section-company-name">Caption 1</div>
        <div class="description">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          Commodi ea eius facilis fugit id, incidunt nemo nobis non numquam odio odit suscipit tenetur ut voluptatum.
          Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          Commodi ea eius facilis fugit id, incidunt nemo nobis non numquam odio odit suscipit tenetur ut voluptatum.</div>
        <div class="join-btn">
          <button type="button" (click)="showJoinPopUp()">Join</button>
        </div>
      </div>
    </div>
    <div class="section-3">
      <div class="join-section">
        <div class="caption section-company-name">Caption 2</div>
        <div class="description">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          Commodi ea eius facilis fugit id, incidunt nemo nobis non numquam odio odit suscipit tenetur ut voluptatum.
          Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          Commodi ea eius facilis fugit id, incidunt nemo nobis non numquam odio odit suscipit tenetur ut voluptatum.Lorem ipsum
          dolor sit amet, consectetur adipisicing elit.
          Commodi ea eius facilis fugit id, incidunt nemo nobis non numquam odio odit suscipit tenetur ut voluptatum.</div>
        <div class="join-btn">
          <button type="button" (click)="showJoinPopUp()">Join</button>
        </div>
      </div>
    </div>
    <div class="section-3">
      <div class="join-section">
        <div class="caption section-company-name">Caption 3</div>
        <div class="description">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          Commodi ea eius facilis fugit id, incidunt nemo nobis non numquam odio odit suscipit tenetur ut voluptatum.
          Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          Commodi ea eius facilis fugit id, incidunt nemo nobis non numquam odio odit suscipit tenetur ut voluptatum.</div>
        <div class="join-btn">
          <button type="button" (click)="showJoinPopUp()">Join</button>
        </div>
      </div>
    </div>


    <div class="section-2">
      <h2 class=" section-title">Why Partner <span class="section-company-name">with Us?</span></h2>
      <div class="section-2-list">
        <div class="list-items">
          <figure>
            <img [src]='"/assets/images/technology.png"' alt="Cutting-Edge Technology">
            <figcaption class="section-title">Cutting-Edge Technology</figcaption>
          </figure>
          <p> Access to our state-of-the-art IT solutions and cutting-edge technologies.</p>
        </div>

        <div class="list-items">
          <figure>
            <img [src]='"/assets/images/business-people-discussing-on-analysis-graph.svg"' alt="Mutual Growth">
            <figcaption class="section-title">Mutual Growth</figcaption>
          </figure>
          <p>Collaborative opportunities for mutual business growth and expansion.</p>
        </div>

        <div class="list-items">
          <figure>
            <img [src]='"/assets/images/support2.png"' alt="Dedicated Support">
            <figcaption class="section-title">Dedicated Support</figcaption>
          </figure>
          <p>A committed partnership team ready to support your initiatives.</p>
        </div>

        <div class="list-items">
          <figure>
            <img [src]='"/assets/images/innovation.png"' alt="Innovative Solutions">
            <figcaption class="section-title">Innovative Solutions</figcaption>
          </figure>
          <p>Be part of a journey to create and deliver innovative solutions to clients worldwide.</p>
        </div>
      </div>
    </div>


  </div>
</section>
