<div class="container">
  <section class="hero-section">

  </section>


  <div class="eliminate-section">
    <div class="eliminate-wrapper">

      <div class="eliminate-grid">

        <div class="eliminate-flexbox-left">

          <img
            [src]="logoUrl"
            alt="Eliminate Image"
            class="eliminate-user-image">
        </div>

        <div class="eliminate-flexbox-right">
          <div class="section-block">
            <h2 class="section-title">Our <span
              class="section-title-inner-style">Story</span></h2>

            <div class="section-text"> Each journey has its own unique story. Ours is just beginning!</div>
            <div class="section-text">
              <span class="eliminate-list-span">Otecsys Plus lls </span> is an IT company founded in 2023. We are the
              team with great experience in various industries,
              have witnessed in variety of problems and have a number of ideas on how to solve them and bring benefits
              to people’s lives.
              The projects that we want to implement are very different, but they pursue similar goals:

              <ul class="list">
                <li>Help to grow business,</li>
                <li>Create conditions for receiving passive income.</li>
              </ul>
            </div>
            <div class="section-text"> If you're interested in learning more about our products and services,
              and how they can benefit your business, please don't hesitate to contact us!
              We look forward to hearing from you!
            </div>

          </div>

          <div class="start-btn">
            <a href="/contacts" >Let’s Start</a>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

