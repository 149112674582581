<div class="header-container">

  <div class="header-top-area" *ngIf="isDesktopView">

    <div class="logo">
      <img [src]="logoUrl" alt="Logo" (click)="navigateTo('home')">
    </div>
    <div class="header-first-section">
      <div class="blocks">
        <div class="header-right-block">
          <a href="mailto:info&#64;otecsys.org" class="footer-contact-link-item">info&#64;otecsys.org</a>
          <div class="header-top-link-separator"></div>
          <div class="footer-contact-item">(+374) 97 28 22 00</div>
        </div>

        <div class="header-left-block">
          <div class="login-content">
            <div class="login">
              <button type="button">Login</button>
            </div>
          </div>
          <div class="header-top-link-separator"></div>
          <div class="toggle">
            <app-toggle></app-toggle>
          </div>
        </div>
      </div>
      <div class="header-horizontal-separator" *ngIf="isDesktopView"></div>

      <div class="header">
        <div class="nav-bar" *ngIf="isDesktopView">
          <nav>

            <ul class="nav-items-container">
              <li class="nav-item" [routerLink]="'home'" (focus)="setFocusedNavItem(0)"
                  [class.focused]="focusedNavItem === 0" (keydown.enter)="navigateTo('home')">
                <span>
                  <a class="item-link">Home</a>
                </span>
              </li>

              <li class="nav-item" [routerLink]="'services'" (focus)="setFocusedNavItem(1) "
                  [class.focused]="focusedNavItem === 1" (keydown.enter)="navigateTo('services')">
                <a class="item-link">Services</a>
              </li>

              <li class="nav-item" [routerLink]="'products'" (focus)="setFocusedNavItem(2)"
                  [class.focused]="focusedNavItem === 2" (keydown.enter)="navigateTo('products')">
                <a class="item-link">Products</a>
              </li>

              <li class="nav-item" [routerLink]="'about-us'" (focus)="setFocusedNavItem(3)"
                  [class.focused]="focusedNavItem === 3" (keydown.enter)="navigateTo('about-us')">
                <a class="item-link">About Us</a>
              </li>
              <li class="nav-item" [routerLink]="'partners'" (focus)="setFocusedNavItem(4)"
                  [class.focused]="focusedNavItem === 4" (keydown.enter)="navigateTo('partners')">
                <a class="item-link">Join Us</a>
              </li>
              <li class="nav-item" [routerLink]="'contacts'" (focus)="setFocusedNavItem(5)"
                  [class.focused]="focusedNavItem === 5" (keydown.enter)="navigateTo('contacts')">
                <a class="item-link">Contacts</a>
              </li>
            </ul>
          </nav>

        </div>
      </div>
    </div>

  </div>


  <div class="burger-menu-wrapper" *ngIf="!isDesktopView">
    <div class="logo">
      <img [src]="logoUrl" alt="Logo">
    </div>
    <button class="burger-menu" (click)="openSideBar()" >
      <div class="menu-span"></div>
      <div class="menu-span"></div>
      <div class="menu-span menu-last-span"></div>
    </button>
  </div>


</div>
