import {Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ImageModule} from "primeng/image";
import {CommonModule} from "@angular/common";

@Component({
  selector: 'app-product-details',
  standalone: true,
  imports: [ImageModule, CommonModule],
  templateUrl: './product-details.component.html',
  styleUrl: './product-details.component.scss'
})
export class ProductDetailsComponent {

  public productId!: string | null;

  constructor(
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.productId = this.route.snapshot.paramMap.get('id');
  }

}
