<p-sidebar [(visible)]="sidebarVisible" position="left"
           [styleClass]="isLightTheme() ? 'p-sidebar-close-light-theme' : 'p-sidebar-close-dark-theme'">
  <div class="side-bar-logo">
    <img [src]="logoUrl" alt="Logo">
  </div>

  <ul class="sidebar-nav-items-container">
    <li class="sidebar-nav-item"><app-toggle></app-toggle></li>
    <li class="sidebar-nav-item" [routerLink]="'home'" (click)="sidebarVisible = false"><span><a class="sidebar-item-link">Home</a></span></li>
    <li class="sidebar-nav-item" [routerLink]="'services'" (click)="sidebarVisible = false"><a class="sidebar-item-link">Services</a></li>
    <li class="sidebar-nav-item" [routerLink]="'products'" (click)="sidebarVisible = false"><a class="sidebar-item-link">Products</a></li>
    <li class="sidebar-nav-item" [routerLink]="'about-us'" (click)="sidebarVisible = false"><a class="sidebar-item-link">About Us</a></li>
    <li class="sidebar-nav-item" [routerLink]="'partners'" (click)="sidebarVisible = false"><a class="sidebar-item-link">Join Us</a></li>
    <li class="sidebar-nav-item" [routerLink]="'contacts'" (click)="sidebarVisible = false"><a class="sidebar-item-link">Contacts</a></li>
  </ul>
</p-sidebar>
