import { Component } from '@angular/core';
import {ImageModule} from "primeng/image";
import {AnimateOnScrollModule} from "primeng/animateonscroll";

@Component({
  selector: 'app-contacts',
  standalone: true,
  imports: [ImageModule, AnimateOnScrollModule ],
  templateUrl: './contacts.component.html',
  styleUrl: './contacts.component.scss'
})
export class ContactsComponent {
}
