import {Component} from '@angular/core';
import {ThemeService} from "../../services/theme.service";

@Component({
  selector: 'app-toggle',
  standalone: true,
  imports: [],
  templateUrl: './toggle.component.html',
  styleUrl: './toggle.component.scss'
})
export class ToggleComponent {

  public isChecked!: boolean;
  private localStorageKey = 'focusedNavItem';
  public currentTheme: 'light' | 'dark';

  constructor(private themeService: ThemeService,) {
    this.currentTheme = this.themeService.getCurrentTheme();
  }

  ngOnInit() {
    this.isChecked = this.currentTheme === 'dark';
  }

  public toggleTheme() {
    this.themeService.toggleTheme();
    this.currentTheme = this.themeService.getCurrentTheme();
  }
}
