<div class="popup">
  <div class="popup-content">


    <form [formGroup]="form" (ngSubmit)="submitForm()">
      <div class="user-info">
        <fieldset class="form-columns-2">

          <div class="input-box">
            <p-floatLabel class="p-float-label">
              <input pInputText id="name" formControlName='firstName'/>
              <label for="name">First Name</label>
            </p-floatLabel>
            <small class="p-error block" *ngIf="form.invalid && form.get('firstName')?.touched ">
              <ng-container class="p-error block" *ngIf="form.get('firstName')?.hasError('required')">
                First name is required
              </ng-container>
            </small>
          </div>

          <div class="input-box">
            <p-floatLabel class="p-float-label">
              <input pInputText id="lastName" formControlName='lastName'/>
              <label for="lastName">Last Name</label>
            </p-floatLabel>
            <small class="p-error block" *ngIf="form.invalid && form.get('lastName')?.touched ">
              <ng-container class="p-error block" *ngIf="form.get('lastName')?.hasError('required')">
                Last name is required
              </ng-container>
            </small>
          </div>
        </fieldset>

        <fieldset class="form-columns-1">
          <div class="input-box large">
            <p-floatLabel class="p-float-label" >
              <input pInputText id="email" type="email" formControlName='email'/>
              <label for="email">Email</label>
            </p-floatLabel>
            <small class="p-error block" *ngIf="form.invalid && form.get('email')?.touched ">
              <ng-container class="p-error block" *ngIf="form.get('email')?.hasError('required')">
                Email is required
              </ng-container>
              <ng-container *ngIf="form.controls['email'].hasError('email')">
                Please enter a valid email address (e.g., example&#64;domain.com).
              </ng-container>
            </small>
          </div>
        </fieldset>

        <fieldset class="form-columns-2">
          <div class="input-box">
            <p-floatLabel class="p-float-label">
              <input pInputText id="companyName" type="text" formControlName='companyName'/>
              <label for="companyName">Company Name</label>
            </p-floatLabel>

            <small class="p-error block" *ngIf="form.invalid && form.get('companyName')?.touched ">
              <ng-container class="p-error block" *ngIf="form.get('companyName')?.hasError('required')">
                Company name is required
              </ng-container>
            </small>
          </div>

          <div class="input-box">
            <p-floatLabel class="p-float-label">
              <input pInputText id="companyWebsite" type="url" formControlName='companyWebsite'/>
              <label for="companyWebsite">Company Website</label>
            </p-floatLabel>
          </div>
        </fieldset>

        <fieldset class="form-columns-2">
          <div class="input-box">
            <p-floatLabel class="p-float-label">
              <p-dropdown
                id="headquarters"
                [options]="countries"
                optionLabel="name"
                optionValue="name"
                formControlName="headquarters"></p-dropdown>
              <label for="headquarters">Headquarters</label>
            </p-floatLabel>

            <small class="p-error block" *ngIf="form.invalid && form.get('headquarters')?.touched ">
              <ng-container class="p-error block" *ngIf="form.get('headquarters')?.hasError('required')">
                Headquarters is required
              </ng-container>
            </small>
          </div>

          <div class="input-box">
            <p-floatLabel class="p-float-label">
              <p-dropdown
                id="industry"
                [options]="industries"
                optionLabel="name"
                optionValue="name"
                formControlName="industry"></p-dropdown>
              <label for="industry">Industry</label>
            </p-floatLabel>
          </div>
        </fieldset>


        <fieldset class="form-columns-1">

          <div class="input-box large">
            <p-floatLabel class="p-float-label">
              <p-dropdown
                id="employment"
                [options]="employments"
                placeholder="How many people work at your company"
                optionLabel="name"
                optionValue="name"
                formControlName="employment"></p-dropdown>
              <label htmlFor="employment">How many people work at your company</label>
            </p-floatLabel>
          </div>
        </fieldset>

        <fieldset class="form-columns-1 last-part">
          <div class="input-box large">
            <p-floatLabel class="p-float-label message-text">
              <textarea
                pInputTextarea
                rows="5"
                cols="10"
                id="message"
                formControlName='message'
              ></textarea>
              <label for="message">Message</label>
            </p-floatLabel>
          </div>
        </fieldset>

      </div>


      <div class="input-box buttons" >
        <small class="warning" *ngIf="showWarning">Please complete all required fields.</small>
        <p-confirmPopup></p-confirmPopup>
        <div class="form-buttons">
          <p-button type="submit"
                    [disabled]="form.invalid || form.disabled"
                    label="Submit">
          </p-button>

          <p-button (click)="closeDialog($event)">Cancel</p-button>
        </div>
      </div>
    </form>


  </div>
</div>
