import { Component } from '@angular/core';
import {FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {ButtonModule} from "primeng/button";
import {InputTextareaModule} from "primeng/inputtextarea";
import {InputTextModule} from "primeng/inputtext";
import {DynamicDialogRef} from "primeng/dynamicdialog";
import {FloatLabelModule} from "primeng/floatlabel";

@Component({
  selector: 'app-join-popup',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, ButtonModule, InputTextareaModule, InputTextModule, FloatLabelModule ],
  templateUrl: './join-popup.component.html',
  styleUrl: './join-popup.component.scss'
})
export class JoinPopupComponent {

  public form = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    subject: ['',Validators.required],
    message: ['',Validators.required,]
  });


  constructor(private fb: FormBuilder, public ref: DynamicDialogRef,) {}


  public sendMessage() {
    if (this.form.valid) {
      this.ref.close(this.form.value)
    } else {
      console.log(this.form)
    }
  }


}
