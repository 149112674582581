<div class="footer-top" *ngIf="isVisible">
  <div class="section-gap-130">
    <div class="container">
      <div class="footer-block">
        <div class="footer-about-work">
          <div class="logo">
            <img [src]="logoUrl" alt="logo" (click)="navigateTo('home')">
            <p> Welcome to Otecsys Plus.</p>
          </div>

          <div class="footer-social-menu">
            <ul>
              <li>
                <a href="https://www.facebook.com/Otecsys/" aria-label="Share on Facebook">
                  <i class="fab fa-facebook-f icon" role="img" aria-hidden="true"></i>
                </a>
              </li>

              <li>
                <a href="https://www.behance.net/search/projects/otecsys?tracking_source=typeahead_nav_recent_suggestion"
                   target="_blank" aria-label="Share on Behance">
                  <i class="fab fa-behance icon" role="img" aria-hidden="true"></i>
                </a>
              </li>

              <li>
                <a href="https://www.linkedin.com/in/otecsysorg/" target="_blank" aria-label="Share on LinkedIn">
                  <i class="fab fa-linkedin-in icon" role="img" aria-hidden="true"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>


        <div class="footer-contact-widget">
          <div class="footer-contact-block">
            <div class="footer-contact-item">
              <span aria-label="map marker"><i class="pi pi-map-marker" role="img" aria-hidden="true"></i></span>
              <a href="https://www.google.com/maps?q=40.2217,44.492944" target="_blank">Armenia, Yerevan, Pirumyan 45/7</a>
            </div>

            <div class="footer-contact-item">
              <span aria-label="phone"><i class="pi pi-phone" role="img" aria-hidden="true"></i></span>
              <a class="footer-contact-item" href="tel:+37497282200">(+374) 97 28 22 00</a>
            </div>
            <div class="footer-contact-item">
              <span aria-label="e-mail"><i class="pi pi-envelope" role="img" aria-hidden="true"></i></span>
              <a href="mailto:info&#64;otecsys.org" class="footer-contact-link-item">info&#64;otecsys.org</a>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</div>
