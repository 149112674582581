import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import { RouterModule} from "@angular/router";
import {ThemeService} from "../../services/theme.service";

@Component({
  selector: 'app-about-us',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './about-us.component.html',
  styleUrl: './about-us.component.scss'
})
export class AboutUsComponent implements OnInit {

  public logoUrl!: string

  constructor(private renderer: Renderer2,
              private el: ElementRef,
              private themeService:ThemeService) {}

  ngOnInit(): void {
    this.getImageByTheme();
    setTimeout(() => {
      this.triggerFadeInLeftAnimation();
      this.triggerFadeInRightAnimation();
    }, 300);

    this.themeService.themeChanged.subscribe(theme => {
      this.getImageByTheme();
    });
  }

  private triggerFadeInLeftAnimation(): void {
    const element = this.el.nativeElement.querySelector('.eliminate-flexbox-left');
    if (element) {
      this.renderer.addClass(element, 'fadeInLeft');
    }
  }

  public getImageByTheme() {
    let theme = localStorage.getItem('currentTheme')
    if (theme === 'dark' || !theme) {
      this.logoUrl = 'assets/images/About-dark.png'
    }
    else {
      this.logoUrl = 'assets/images/About.png'
    }
  }

  private triggerFadeInRightAnimation(): void {
    const element = this.el.nativeElement.querySelector('.eliminate-flexbox-right');
    if (element) {
      this.renderer.addClass(element, 'fadeInRight');
    }
  }

}
