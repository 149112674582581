import {Component} from '@angular/core';
import {DialogService, DynamicDialogModule, DynamicDialogRef} from "primeng/dynamicdialog";
import {GetPartnersPopupComponent} from "./get-partners-popup/get-partners-popup.component";
import {ConfirmationService, MessageService} from "primeng/api";
import {ToastModule} from "primeng/toast";
import {JoinPopupComponent} from "./join-popup/join-popup.component";

@Component({
  selector: 'app-getpartners',
  standalone: true,
  imports: [DynamicDialogModule, ToastModule  ],
  templateUrl: './getpartners.component.html',
  styleUrl: './getpartners.component.scss',
  providers: [DialogService,MessageService,ConfirmationService]
})
export class GetpartnersComponent {

  public     ref: DynamicDialogRef | undefined;

  constructor(public dialogService: DialogService,public messageService: MessageService) {}


  public showDialog(component: any, header: string, width: string) {
    const theme = localStorage.getItem('currentTheme');
    const backgroundColor = theme === 'dark' ? 'rgba(5,118,177,0.15)' : 'rgba(89, 181, 217, 0.3)';

    this.ref = this.dialogService.open(component, {
      header: header,
      width: width,
      modal: true,
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      },
      style: {
        'background': backgroundColor,
        'border-radius': '20px'
      }
    });

    this.ref.onClose.subscribe((product: any) => {
      console.log('prod ' + JSON.stringify(product));
      if (product && !product.isClosed) {
        this.messageService.add({ severity: 'info', summary: 'Message sent successfully' });
      }
    });
  }

  public showPopUp() {
    this.showDialog(GetPartnersPopupComponent, 'Partner Registration Form', '40vw');
  }

  public showJoinPopUp() {
    this.showDialog(JoinPopupComponent, 'Partner Registration Form', '35vw');
  }
}

