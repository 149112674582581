import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  public showRegisterPage!: boolean;
  public showLoginPage!: boolean;
  public openLeftBar$ = new Subject<{ value: boolean, section?: string }>();

  public constructor(private router: Router,) {}

  public navigateToHome(categoryName?: string): Promise<boolean> {
    const category = categoryName || 'home';
    return this.router.navigate([`/${category}`]);
  }

  public openLeftBar() {
    this.openLeftBar$.next({value: true});
  }

  public closeLeftBar() {
    this.openLeftBar$.next({value: false});
  }

  public closeAllPages() {
    this.closeRegisterPage();
    this.closeLoginPage();
  }

  public closeRegisterPage() {
    this.showRegisterPage = false;
  }

  public closeLoginPage() {
    this.showLoginPage = false;
  }
}


