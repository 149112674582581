<div class="container">

  <div class="page-info">
    <h2 class="section-title">Welcome to Our <span class="section-title-inner-style">Products </span> Page</h2>
    <p>Explore our diverse range of products designed to cater to various needs and interests.</p>
  </div>



  <div class="figure-1">


    <figure class="snip1543">
      <img src="/assets/products/casino.png" alt="sample108" />

      <figcaption>
        <h3>Game Universe </h3>
        <p>Universal gaming platform with wide range functionality. suitable for iGaming and and for other types of games.
        </p>
        <a [routerLink]="['/product', 1]">Read more...</a>

      </figcaption>



    </figure>

    <figure class="snip1543">
      <img src="https://images-na.ssl-images-amazon.com/images/I/91ViVYMWl0L.jpg" alt="sample101" />
      <figcaption>
        <h3>Awesome games</h3>
        <p>Under the Awesome Games brand, we are preparing to bring our games to the world </p>
        <a [routerLink]="['/product', 2]">Read more...</a>
      </figcaption>
    </figure>

    <figure class="snip1543">
      <img src="/assets/products/kapvenq.png" alt="sample100" />
      <figcaption>
        <h3>Kapvi</h3>
        <p>24/7 assistant for you which will help you to find healthy food, earn money, and many more other things.</p>
        <a [routerLink]="['/product', 3]">Read more...</a>
      </figcaption>

    </figure>

  </div>
</div>






