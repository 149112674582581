<div class="app-container">

  <div *ngIf="isHomePage">
    <app-three-jsscene></app-three-jsscene>

  </div>

  <!--  <div class="animation">-->
  <!--    <app-particle-background></app-particle-background>-->
  <!--  </div>-->


  <div class="header">
    <app-header></app-header>
  </div>

  <div>
    <app-side-bar></app-side-bar>
  </div>

  <main>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </main>

  <footer class="footer" [style.display]="showFooter ? 'block' : 'none'">
    <app-footer></app-footer>
  </footer>

  <div id="greeting">
    <div #animationContainer></div>
  </div>

  <div class="scroll-to-top" (click)="scrollToTop()" [ngClass]="{'show': showScrollButton}">
    <i class="fas fa-arrow-up"></i>
  </div>

</div>


