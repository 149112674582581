import {Component} from '@angular/core';
import {FormBuilder, ReactiveFormsModule, Validator, Validators} from "@angular/forms";
import {countries} from "../../../util/countries";
import {industries} from "../../../util/industries";
import {CommonModule} from "@angular/common";
import {DropdownModule} from "primeng/dropdown";
import {InputTextModule} from "primeng/inputtext";
import {InputTextareaModule} from "primeng/inputtextarea";
import {ConfirmPopupModule} from "primeng/confirmpopup";
import {DynamicDialogRef} from "primeng/dynamicdialog";
import {ButtonModule} from "primeng/button";
import {ConfirmationService} from "primeng/api";
import {FloatLabelModule} from "primeng/floatlabel";

@Component({
  selector: 'app-get-partners-popup',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule,DropdownModule, InputTextModule, InputTextareaModule,ConfirmPopupModule,
    ButtonModule, FloatLabelModule  ],
  providers:[ConfirmationService],
  templateUrl: './get-partners-popup.component.html',
  styleUrl: './get-partners-popup.component.scss'
})
export class GetPartnersPopupComponent {

public showWarning!: boolean;

  public closeDialog(event: Event) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Do you want to close the form?',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: 'p-button-danger p-button-sm',
      accept: () => {
        this.ref.close({isClosed: true});
      },
      reject: () => {
      }
    });
  }

  public countries = countries;
  public industries = industries;
  public employments = [
    {name: '1-50' },
    {name: '51-100' },
    {name: '101-200' },
    {name: '200+' },

  ]

  public showForm: boolean = false;
  public form = this.fb.group({
    firstName:['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    companyName:['', Validators.required],
    companyWebsite:[''],
    headquarters: [null,  Validators.required],
    industry:[''],
    employment:[''],
    message: ['']
  });

  constructor(private fb: FormBuilder, public ref: DynamicDialogRef, private confirmationService: ConfirmationService){}


  public sendMessage() {
    console.log('Valid')
  }

  closeForm() {
    this.showForm = false;
  }

  submitForm() {
    if (this.form.valid) {
      this.sendMessage();
      this.ref.close(this.form.value)
    } else {
      console.log(this.form)
      this.markAllFormControlsAsTouched();
    }
  }

  markAllFormControlsAsTouched(): void {
    Object.values(this.form.controls).forEach(control => {
      control.markAsTouched();
      this.showWarning = true
    });
  }

}
