import {Component, OnInit} from '@angular/core';
import {AnimateOnScrollModule} from "primeng/animateonscroll";
import {FormBuilder, ReactiveFormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {InputTextareaModule} from "primeng/inputtextarea";
import {ButtonModule} from "primeng/button";
import {CommonModule} from "@angular/common";
import VanillaTilt from "vanilla-tilt";
import {FloatLabelModule} from "primeng/floatlabel";

@Component({
  selector: 'app-services',
  standalone: true,
  imports: [AnimateOnScrollModule, ReactiveFormsModule, InputTextModule, ButtonModule,
    ReactiveFormsModule, InputTextareaModule, CommonModule, FloatLabelModule ],
  templateUrl: './services.component.html',
  styleUrl: './services.component.scss'
})
export class ServicesComponent implements OnInit {

  constructor(private fb: FormBuilder,) {}

  public form = this.fb.group({
    email: [''],
    subject: [''],
    message: ['']
  });

  public sendMessage() {
    console.log(this.form.value)
  }

  public ngOnInit(): void {}

  ngAfterViewInit(): void {
    const elements = document.querySelectorAll('.vanillaTitle');
    elements.forEach((element: any) => {
      VanillaTilt.init(element as HTMLElement);
    });
  }
}


