import {Component, ElementRef, HostListener, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {FooterComponent} from "./shared/components/footer/footer.component";
import {HeaderComponent} from "./shared/components/header/header.component";
import {ThemeService} from "./shared/services/theme.service";
import {filter} from "rxjs/operators";
import {SideBarComponent} from "./shared/components/side-bar/side-bar.component";
import {ParticleBackgroundComponent} from "./shared/components/particle-background/particle-background.component";
import * as lottie from 'lottie-web';
import {ThreeJSSceneComponent} from "./three-jsscene/three-jsscene.component";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, FooterComponent, HeaderComponent, SideBarComponent, ParticleBackgroundComponent, ThreeJSSceneComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  animations: []
})
export class AppComponent {

  public showFooter: boolean = true;
  public timeout!: any;
  public isHomePage!: boolean;
  public showScrollButton: boolean = false;


  @HostListener('window:scroll', ['$event'])
  @HostListener('window:keydown', ['$event'])
  @HostListener('window:click', ['$event'])
  onUserInteraction(event: Event): void {
    this.hideGreeting();
    this.resetTimer();
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (window.pageYOffset > 100) { // Adjust the offset value as needed
      this.showScrollButton = true;
    } else {
      this.showScrollButton = false;
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  @ViewChild('animationContainer', {static: true}) animationContainer!: ElementRef;

  constructor(private router: Router) {}

  ngOnInit(): void {
    setTimeout(() => {
      document.querySelector('.header')?.classList.add('show');
    }, 300);

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
      this.showFooter = event.url !== '/home';
    });
    this.initialize();
    this.loadLottieAnimation();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      this.isHomePage = event.url == '/home' && event.urlAfterRedirects == '/home';
    });
  }

  loadLottieAnimation(): void {
    const animationOptions: lottie.AnimationConfigWithPath = {
      container: this.animationContainer.nativeElement,
      renderer: 'svg', // or 'canvas'
      loop: true,
      autoplay: true,
      path: './assets/images/Animation.json', // path to your JSON file
    };


    (lottie as any).loadAnimation(animationOptions as any);
  }

  private showGreeting(): void {
    const greetingElement = document.getElementById('greeting');
    if (greetingElement) {
      greetingElement.style.display = 'block';
    }
  }

  private resetTimer(): void {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      const greetingElement = document.getElementById('greeting');
      if (greetingElement) {
        greetingElement.style.display = 'none';
        this.showGreeting();
      }
    }, 30000);
  }

  private initialize(): void {
    document.addEventListener('mousemove', () => this.resetTimer());
    document.addEventListener('keypress', () => this.resetTimer());

    this.resetTimer();
  }

  private hideGreeting(): void {
    const greetingElement = document.getElementById('greeting');
    if (greetingElement) {
      greetingElement.style.display = 'none';
    }
  }
}
