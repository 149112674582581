import {Component} from '@angular/core';
import {ThemeService} from "../../services/theme.service";
import {NavigationEnd, Router} from "@angular/router";
import {CommonModule} from "@angular/common";
import {filter} from "rxjs/operators";

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  public logoUrl!: string;
  public isVisible!: boolean;


  constructor(private themeService: ThemeService, private router: Router,) {
  }


  public ngOnInit() {
    this.getLogoBtTheme();
    this.themeService.themeChanged.subscribe(theme => {
      this.getLogoBtTheme();
    });
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      this.isVisible = event.url !== '/' && event.urlAfterRedirects !== '/';
    });
  }

  public getLogoBtTheme() {
    let theme = localStorage.getItem('currentTheme')
    if (theme === 'dark' || !theme) {
      this.logoUrl = 'assets/images/Logo3.png'
    } else {
      this.logoUrl = 'assets/images/Logo1.png'
    }
  }

  navigateTo(route: string) {
    this.router.navigateByUrl(route);
  }
}
