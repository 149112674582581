import {Component, HostListener} from '@angular/core';
import {CommonModule} from "@angular/common";
import {SidebarModule} from "primeng/sidebar";
import {ButtonModule} from "primeng/button";
import {NavigationEnd, Router, RouterModule} from "@angular/router";
import {NavigationService} from "../../services/navigation.service";
import {ToggleComponent} from "../toggle/toggle.component";
import {ThemeService} from "../../services/theme.service";


@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, SidebarModule, ButtonModule, RouterModule, ToggleComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {

  public isDesktopView: boolean = true;
  public focusedNavItem!: any;
  private localStorageKey = 'focusedNavItem';
  public logoUrl!: string;
  totalNavItems: number = 6;

  constructor(private navigationService: NavigationService,
              private router: Router,
              private themeService: ThemeService) {
  }

  public ngOnInit() {
    this.checkViewport();
    this.getLogoBtTheme()

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const url = event.urlAfterRedirects;
        localStorage.setItem(this.localStorageKey, this.focusedNavItem);
        if (url.includes('home')) {
          this.focusedNavItem = 0;
        }
        if (url.includes('services')) {
          this.focusedNavItem = 1;
        }
        if (url.includes('products')) {
          this.focusedNavItem = 2;
        }
        if (url.includes('about-us')) {
          this.focusedNavItem = 3;
        }
        if (url.includes('partners')) {
          this.focusedNavItem = 4;
        }
        if (url.includes('contacts')) {
          this.focusedNavItem = 5;
        }

      }
    });
    this.focusedNavItem = +(localStorage.getItem(this.localStorageKey) || 0);

    this.themeService.themeChanged.subscribe(theme => {
      this.getLogoBtTheme();
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.checkViewport();
  }


  navigateTo(route: string) {
    this.router.navigateByUrl(route);
  }


  private checkViewport(): void {
    this.isDesktopView = window.innerWidth > 1240;
  }

  public openSideBar() {
    this.navigationService.openLeftBar();
  }

  public setFocusedNavItem(index: number): void {
    this.focusedNavItem = index;
  }

  public getLogoBtTheme() {
    let theme = localStorage.getItem('currentTheme')
    if (theme === 'dark' || !theme) {
      this.logoUrl = 'assets/images/Logo3.png'
    } else {
      this.logoUrl = 'assets/images/Logo1.png'
    }
  }
}
