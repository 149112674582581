import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private readonly THEME_KEY = 'currentTheme';
  private currentTheme: 'light' | 'dark' = 'light';
  public themeChanged: EventEmitter<string> = new EventEmitter<string>();


  constructor() {
    this.currentTheme = (localStorage.getItem(this.THEME_KEY) as 'light' | 'dark') || 'dark';
    this.applyTheme();
  }

  toggleTheme() {
    this.currentTheme = this.currentTheme === 'light' ? 'dark' : 'light';
    this.applyTheme();
    localStorage.setItem(this.THEME_KEY, this.currentTheme);
    this.themeChanged.emit(this.currentTheme);
  }

  getCurrentTheme(): 'light' | 'dark' {
    const currentTheme = localStorage.getItem(this.THEME_KEY) as 'light' | 'dark';
    return  currentTheme ? currentTheme : 'dark'
  }

  private applyTheme() {
    document.body.classList.remove('light-theme', 'dark-theme');
    document.body.classList.add(`${this.currentTheme}-theme`);
  }
}
