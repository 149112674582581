
<div class="container">
  <div class="product-detail-wrapper">
    <div class="detail-content">
      <div class="inner-info" *ngIf="productId === '1'">
        <h2 class="product-title section-title">
          <span class="section-title-inner-style"> Game Universe </span>
        </h2>
        <div class="product-detail">
          <div class="product-description">
            <p>
              <span class="tab">Game universe is a gaming platform which we are now developing.</span>
              It provides a wide range of functionality for both – game developers and players.
              It’s includes bonus programs, tournaments, sweepstakes, various payment systems, wallet, personal account, notifications, chat and CRM system.
              Game universe is a universal platform.
            </p>
            <p>
              It’s suitable and for casino and for other types of games where game developers can integrate their games without thinking of creating
              CRM system or adding payment systems…
              We want to understand, inform, entertain, and
              celebrate fans by building the best entertainment and gaming communities, content, services, and experiences.
            </p>
          </div>

          <div>
            <img src="/assets/products/casino.png" class="product-img" alt="Game Universe">
          </div>
        </div>

        <span  class="btn-link">If you have any questions, feel free to <a  href="/services#ServiceSection">connect with us!</a></span>
      </div>


      <div class="inner-info second-section" *ngIf="productId === '2'">
        <h2 class="product-title section-title">
          <span class="section-title-inner-style"> Awesome games </span>
        </h2>
        <div class="product-detail">
          <div class="product-description">
            <p>
              Under the Awesome Games brand, we are preparing to bring our games to the world.
              Now we are working on development of arcade games with perfect graphic and various interesting features.
              Our arcade games have multiplayer mode support. Several players from all over the world can play simultaneously,
              compete with each other, gain unique experience and have a great time. Our games can be played on the machines, phones and online.
            </p>
            <p>
              As you know your profitability as a game operator relies partially on the types of games that you have in your establishment.
              Our games can make more attractive your business.
            </p>
          </div>

          <div>
            <img src="/assets/products/casino.png" class="product-img" alt="Awesome games">
          </div>

          <div class="screen-container">

            <div class="card">
              <div class="wrapper">
                <img src="assets/images/b1.png" class="cover-image" alt="Dungeon of Mermaids"/>
              </div>
              <img src="assets/images/b15.png" class="character" alt="Poseidon" />
            </div>

            <div class="card">
              <div class="wrapper">
                <img src="assets/images/b4.png" class="cover-image" alt="Jackpot"/>
              </div>
              <img src="assets/images/b5.png" class="character" alt="Jackpot"/>
            </div>

            <div class="card">
              <div class="wrapper">
                <img src="assets/images/b6.png" class="cover-image" alt="ViralKiss" />
              </div>
              <img src="assets/images/b7.png" class="character" alt="Fruits" />
            </div>

            <div class="card">
              <div class="wrapper">
                <img src="assets/images/b8.png" class="cover-image" alt="Monsters Nook" />
              </div>
              <img src="assets/images/b9.png" class="character" alt="Octopus" />
            </div>

          </div>
        </div>

        <span  class="btn-link">If you have any questions, feel free to <a  href="/services#ServiceSection" >connect with us!</a></span>
      </div>


      <div class="inner-info" *ngIf="productId === '3'">
        <h2 class="product-title section-title">
          <span class="section-title-inner-style"> Kapvi </span>
        </h2>
        <div class="product-detail">
          <div class="product-description">
            <p>
              KapVi is an application created to help people related to the agricultural sector.
              It is designed to become a universal assistant and help in the implementation and/or solution of various tasks.
            </p>
            <p>
            </p>
          </div>

          <div>
            <img src="/assets/products/kapvenq.png" class="product-img" alt="Kapvi">
          </div>
        </div>

        <span  class="btn-link">If you have any questions, feel free to <a href="/services#ServiceSection">connect with us!</a></span>
      </div>

    </div>
  </div>
</div>

